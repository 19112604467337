export default {
  mounted(el) {
    const id = Math.random().toString(36).substring(2, 11);

    // Create the SVG filter once
    const svgNS = "http://www.w3.org/2000/svg";
    const svg = document.createElementNS(svgNS, "svg");
    svg.setAttribute("style", "position: absolute; width: 0; height: 0; pointer-events: none;");
    svg.setAttribute("data-filter-id", id);

    const filter = document.createElementNS(svgNS, "filter");
    filter.setAttribute("id", `wavy-${id}`);

    const feTurbulence = document.createElementNS(svgNS, "feTurbulence");
    feTurbulence.setAttribute("type", "fractalNoise");
    feTurbulence.setAttribute("baseFrequency", "0.004");
    feTurbulence.setAttribute("numOctaves", "1");

    const feDisplacementMap = document.createElementNS(svgNS, "feDisplacementMap");
    feDisplacementMap.setAttribute("in", "SourceGraphic");
    feDisplacementMap.setAttribute("scale", "10");

    const animate = document.createElementNS(svgNS, "animate");
    animate.setAttribute("attributeName", "baseFrequency");
    animate.setAttribute("values", "0.004; 0.027; 0.004");
    animate.setAttribute("dur", "20s");
    animate.setAttribute("repeatCount", "indefinite");

    feTurbulence.appendChild(animate);
    filter.appendChild(feTurbulence);
    filter.appendChild(feDisplacementMap);
    svg.appendChild(filter);

    document.body.appendChild(svg);

    // Apply the filter on hover
    el.addEventListener("mouseenter", () => {
      el.style.filter = `url(#wavy-${id})`;
      animate.beginElement(); // Start the animation
    });

    // Remove the filter on mouse leave
    el.addEventListener("mouseleave", () => {
      el.style.filter = "none";
    });

    // Cleanup the SVG filter when the element is unmounted
    el._wavyHoverCleanup = () => {
      svg.remove();
    };
  },
  unmounted(el) {
    if (el._wavyHoverCleanup) {
      el._wavyHoverCleanup();
    }
  },
};
