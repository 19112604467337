import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import modelUrl from '@/assets/models/happy.glb';
import matUrl from '@/assets/models/mats/plastic.png';

let instance = null;

function smileyFriendAnim(group, meshes) {
  let isRunning = true;
  
  // Initialize meshes with transparency
  meshes.forEach(mesh => {
    if (mesh.material) {
      mesh.material.transparent = true;
      mesh.material.opacity = 0;
    }
  });
  
  // Animation state
  const state = {
    time: 0,
    opacity: 0,
    targetOpacity: 1,
    rotationSpeed: 0.006,
    bounceHeight: 1,
    bounceSpeed: 0.02
  };
  
  function animate() {
    if (!isRunning) return;
    
    // Update time
    state.time += state.bounceSpeed;
    
    // Handle fade in
    if (state.opacity < state.targetOpacity) {
      state.opacity += 0.01;
      meshes.forEach(mesh => {
        if (mesh.material) {
          mesh.material.opacity = state.opacity;
        }
      });
    }
    
    // Apply rotation and bounce
    group.rotation.y += state.rotationSpeed;
    group.position.y = Math.sin(state.time) * state.bounceHeight;
  }
  
  function cleanup() {
    isRunning = false;
  }
  
  return { animate, cleanup };
}

export function initHappyFriend(renderer, parentScene) {
    return initSmileyFriend(renderer, parentScene);
}

export function initSmileyFriend(renderer, parentScene) {
    // Return existing instance if already initialized
    if (instance) {
        console.log('Smiley face already initialized, returning existing instance');
        return instance;
    }

    // Create main group for smiley face
    const group = new THREE.Group();

    // Setup camera specifically for smiley face
    const camera = new THREE.PerspectiveCamera(
        30,
        window.innerWidth / window.innerHeight,
        0.1,
        100  // Increased far plane
    );
    camera.position.z = 50;

    // Setup controls for smiley face camera
    const controls = new OrbitControls(camera, renderer.domElement);

    // Basic control settings
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.minDistance = 20;
    controls.maxDistance = 90;

    // Full rotation settings
    controls.maxPolarAngle = Math.PI; // Top limit
    controls.minPolarAngle = 0; // Bottom limit
    controls.maxAzimuthAngle = Infinity;
    controls.minAzimuthAngle = -Infinity;

    // Additional unrestricted settings
    controls.enableRotate = true;
    controls.rotateSpeed = 1.0;
    controls.enablePan = false;
    controls.enableZoom = true;
    controls.target.set(0, 0, 0);

    // Create placeholders for loaded assets
    const geometries = [];
    const materials = [];
    const meshes = [];

    // Setup loading manager
    const loadingManager = new THREE.LoadingManager();
    loadingManager.onError = function (url) {
        console.error('Error loading', url);
    };
    loadingManager.onProgress = function (url, loaded, total) {
        console.log(`Loading file: ${url}\nLoaded ${loaded} of ${total} files.`);
    };

    // Setup loaders with loading manager
    const loader = new GLTFLoader(loadingManager);
    const dracoLoader = new DRACOLoader(loadingManager);
    dracoLoader.setDecoderPath('/draco/');
    dracoLoader.setDecoderConfig({ type: 'wasm' });
    loader.setDRACOLoader(dracoLoader);

    const textureLoader = new THREE.TextureLoader(loadingManager);

    return new Promise((resolve, reject) => {
        // Load the matcap texture
        const matcapTexture = textureLoader.load(matUrl, () => {
            // Load the GLB model after texture is loaded
            loader.load(modelUrl, (gltf) => {
                // Process and add the GLB model
                gltf.scene.traverse((child) => {
                    if (child.isMesh) {
                        const remeshedGeometry = new THREE.BufferGeometry().copy(child.geometry);
                        remeshedGeometry.computeVertexNormals();
                        geometries.push(remeshedGeometry);

                        // Create and assign matcap material
                        const material = new THREE.MeshMatcapMaterial({
                            matcap: matcapTexture,
                            side: THREE.DoubleSide,
                            flatShading: false,
                            depthTest: true,
                            depthWrite: true
                        });
                        materials.push(material);
                        child.material = material;

                        // Add mesh to meshes array for animation
                        meshes.push(child);
                    }
                });

                // Add the model to our group
                group.add(gltf.scene);

                // Setup animations using the external animation file
                const { animate, cleanup: animCleanup } = smileyFriendAnim(group, meshes);

                // Create instance with all necessary components
                instance = {
                    group,
                    camera,
                    controls,
                    geometries,
                    materials,
                    update: animate,  // Animation update function
                    cleanup() {
                        // Cleanup animation
                        animCleanup();

                        // Dispose controls
                        controls.dispose();

                        // Dispose textures
                        matcapTexture.dispose();

                        // Dispose geometries and materials
                        geometries.forEach(g => g.dispose());
                        materials.forEach(m => m.dispose());

                        instance = null;
                    }
                };

                resolve(instance);
            }, undefined, reject);
        }, undefined, reject);
    });
}

export function disposeHappyFace() {
    if (instance) {
        instance.cleanup();
        instance = null;
    }
}

export function disposeSmileyFace() {
    disposeHappyFace();
}

