export const graphicLogosUrls = {
    0: new URL('../assets/img/graphics-arte.png', import.meta.url).href,
    1: new URL('../assets/img/graphics-auc.png', import.meta.url).href,
    2: new URL('../assets/img/graphics-bd.png', import.meta.url).href,
    3: new URL('../assets/img/graphics-csr.png', import.meta.url).href,
    4: new URL('../assets/img/graphics-double.png', import.meta.url).href,
    5: new URL('../assets/img/graphics-hgz.png', import.meta.url).href,
    6: new URL('../assets/img/graphics-neoera.png', import.meta.url).href,
    7: new URL('../assets/img/graphics-pride-1.png', import.meta.url).href,
    8: new URL('../assets/img/graphics-pride-2.png', import.meta.url).href,
    9: new URL('../assets/img/graphics-r12.png', import.meta.url).href,
    10: new URL('../assets/img/graphics-sky.png', import.meta.url).href,
    11: new URL('../assets/img/graphics-tmmh.png', import.meta.url).href
};

const pageTitle = 'Logo';
const description = [
  {
    subHead: 'Branding + Identity',
    body: 'Designs that are playful yet bold in spirit while remaining contextualized. Engaging with minimal form for a potential brand experience.'
  }
];

export { pageTitle, description };