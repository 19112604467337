import * as THREE from 'three';
import starTexture from '@/assets/img/star.png';

let backgroundInstance = null;

export async function initCosmos() {
    // Return existing instance if already initialized
    if (backgroundInstance) {
        console.log('Cosmos already initialized, returning existing instance');
        return backgroundInstance;
    }

    // Create background camera matching your reference settings
    const backgroundCamera = new THREE.PerspectiveCamera(
        70, // FOV of 70 degrees
        window.innerWidth / window.innerHeight,
        0.0001, // Very close near plane
        1000000 // Very far far plane
    );
    backgroundCamera.position.z = 0; // Camera at center

    // Create main group
    const backgroundGroup = new THREE.Group();
    
    // Load star texture
    const textureLoader = new THREE.TextureLoader();
    const texture = await new Promise((resolve, reject) => {
        textureLoader.load(
            starTexture, 
            resolve, 
            undefined, 
            reject
        );
    });

    // Star field creation based on reference implementation
    function getStarfield(numStars = 6000, size = 0.6) {
        const verts = [];
        const colors = [];
        
        for (let i = 0; i < numStars; i++) {
            // Random position on sphere, matching reference implementation
            const radius = Math.random() * 500 + 80; // Between 80 and 580
            const u = Math.random();
            const v = Math.random();
            const theta = 2 * Math.PI * u;
            const phi = Math.acos(2 * v - 1);
            
            const x = radius * Math.sin(phi) * Math.cos(theta);
            const y = radius * Math.sin(phi) * Math.sin(theta);
            const z = radius * Math.cos(phi);
            
            verts.push(x, y, z);
            
            // Color based on distance from center, matching reference implementation
            const hue = radius * 0.02;
            const col = new THREE.Color().setHSL(hue, 0.3, Math.random());
            colors.push(col.r, col.g, col.b);
        }
        
        // Create geometry
        const geo = new THREE.BufferGeometry();
        geo.setAttribute('position', new THREE.Float32BufferAttribute(verts, 3));
        geo.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
        
        // Create material
        const mat = new THREE.PointsMaterial({
            size,
            vertexColors: true,
            map: texture,
            transparent: true,
            alphaTest: 0.01,
            depthWrite: false,
            depthTest: false,
            blending: THREE.AdditiveBlending
        });
        
        // Create points
        const points = new THREE.Points(geo, mat);
        return { points, geo, mat };
    }
    
    // Create starfield with settings matching reference
    const { points, geo, mat } = getStarfield(6000, 0.6);
    points.rotation.x = Math.PI; // Flip to match reference
    backgroundGroup.add(points);
    
    // Store all resources for cleanup
    const geometries = [geo];
    const materials = [mat];
    
    // Store the instance with rotation matching reference
    backgroundInstance = {
        group: backgroundGroup,
        camera: backgroundCamera,
        geometries: geometries,
        materials: materials,
        update: () => {
            // Slow rotation matching reference (0.0001)
            backgroundGroup.rotation.y += 0.0001;
        },
        cleanup: () => {
            // Dispose resources
            geometries.forEach(g => g.dispose());
            materials.forEach(m => m.dispose());
            
            // Clear references
            backgroundInstance = null;
        }
    };

    return backgroundInstance;
}

// Add cleanup function that's not actually called
export function disposeBackground() {
    if (backgroundInstance) {
        backgroundInstance.cleanup();
    }
}
