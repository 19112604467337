import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { graphicLogosUrls } from '../data/dataLogo.js';

function buildLogoCubes(renderer) {
    // Define isMobile
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    
    // IMPORTANT: Remove this line - we'll use ThreeView's animation loop instead
    // let animationFrameId = null;
    
    const logoScene = new THREE.Group(); // Create the logoScene group

    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5; // Changed to positive to face cubes

    logoScene.position.y = -.55;
    const light1 = new THREE.DirectionalLight(0xFFFFFF, 2.0);
    light1.position.set(0, 1, 2);
    logoScene.add(light1);
    const light2 = new THREE.AmbientLight(0x404040, 40);
    logoScene.add(light2);
    
    // Store textures for proper disposal later
    const textures = [];
    
    const loader = new THREE.TextureLoader();
    function loadColorTexture(index) {
        const texture = loader.load(graphicLogosUrls[index]);
        texture.colorSpace = 'srgb';
        textures.push(texture); // Store reference
        return texture;
    }
    function createMaterialsFromNumbers(...indices) {
        return indices.map(index => new THREE.MeshPhongMaterial({ map: loadColorTexture(index) }));
    }
    const materials_1 = createMaterialsFromNumbers(0, 1, 2, 3, 4, 5);
    const materials_2 = createMaterialsFromNumbers(6, 7, 8, 9, 10, 11);
    const geometry = new THREE.BoxGeometry(1.3, 1.3, 1.3);
    function makeInstance(geometry, materials, x) {
        const cube = new THREE.Mesh(geometry, materials);
        logoScene.add(cube);
        cube.position.x = x;
        cube.userData = { rotating: true };
        return { cube };
    }
    const cube1 = makeInstance(geometry, materials_2, -1.5);
    const cube2 = makeInstance(geometry, materials_1, 1.5);
    const cubeGroup = new THREE.Group();
    cubeGroup.add(cube1.cube);
    cubeGroup.add(cube2.cube);
    cubeGroup.position.y = -0.25;
    logoScene.add(cubeGroup);
    const controls = new OrbitControls(camera, renderer.domElement); // Replace the dummy element with the actual renderer.domElement
    controls.target.copy(cubeGroup.position);
    controls.enableDamping = true;
    controls.dampingFactor = isMobile ? 0.1 : 0.25;
    controls.rotateSpeed = isMobile ? 2.0 : 1.0;
    controls.autoRotate = true;
    controls.autoRotateSpeed = isMobile ? 1.5 : 2.0;
    controls.screenSpacePanning = false;
    controls.maxPolarAngle = Math.PI;
    controls.minPolarAngle = 0;
    controls.minAzimuthAngle = -Infinity;
    controls.maxAzimuthAngle = Infinity;
    controls.enableZoom = !isMobile;
    controls.minDistance = isMobile ? 4 : 3;
    controls.maxDistance = isMobile ? 6 : 8;
    controls.enablePan = false;
    controls.mouseButtons = {
        LEFT: THREE.MOUSE.ROTATE,
        MIDDLE: null,
        RIGHT: THREE.MOUSE.ROTATE
    };
    controls.touches = {
        ONE: THREE.TOUCH.ROTATE,
        TWO: THREE.TOUCH.ROTATE
    };
    controls.update();

    const raycaster = new THREE.Raycaster();
    // Set raycaster to check the same layer as the camera
    raycaster.layers.set(2); // LOGO_LAYER from ThreeView.vue
    
    const mouse = new THREE.Vector2();
    let globalRotationEnabled = true;
    function onClick(event) {
        // Skip if clicked on a navigation element
        if (event.target && (
            event.target.closest('#nav') || 
            event.target.closest('.btn') ||
            event.target.closest('.nav-secondary')
        )) {
            return;
        }
        
        // Existing code:
        const rect = renderer.domElement.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
        
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects([cube1.cube, cube2.cube]);
        
        console.log("Click detected, intersections:", intersects.length);
        
        if (intersects.length > 0) {
            const intersectedCube = intersects[0].object;
            if (!globalRotationEnabled && !cube1.cube.userData.rotating && !cube2.cube.userData.rotating) {
                cube1.cube.userData.rotating = true;
                cube2.cube.userData.rotating = true;
                globalRotationEnabled = true;
                controls.autoRotate = true;
                return;
            }
            intersectedCube.userData.rotating = !intersectedCube.userData.rotating;
            if (!cube1.cube.userData.rotating && !cube2.cube.userData.rotating) {
                globalRotationEnabled = false;
                controls.autoRotate = false;
            }
        }
    }
    renderer.domElement.addEventListener('click', onClick);

    let touchStartX = 0;
    let touchEndX = 0;
    const initialAutoRotateSpeed = controls.autoRotateSpeed;

    function handleGesture() {
        if (touchEndX < touchStartX) controls.autoRotateSpeed += 0.1;
        if (touchEndX > touchStartX) controls.autoRotateSpeed -= 0.1;
        clearTimeout(window.resetAutoRotateSpeedTimeout);
        window.resetAutoRotateSpeedTimeout = setTimeout(() => {
            controls.autoRotateSpeed = initialAutoRotateSpeed;
        }, 200);
    }

    const eventHandlers = {
        touchStart: (event) => {
            // Check if the touch is on a button or a cube
            if (event.target.closest('#nav') || 
                event.target.closest('.btn') ||
                event.target.closest('.nav-secondary') ||
                isTouchOnCube(event)) {
                // Allow touch events on these elements
                event.stopPropagation(); // Prevent page scrolling
                touchStartX = event.touches[0].screenX;
                const touch = event.touches[0];
                mouse.x = (touch.clientX / window.innerWidth) * 2 - 1;
                mouse.y = -(touch.clientY / window.innerHeight) * 2 + 1;
            } else {
                // Prevent default behavior for other elements
                event.preventDefault();
            }
        },
        touchMove: (event) => {
            // Check if the touch is on a button or a cube
            if (event.target.closest('#nav') || 
                event.target.closest('.btn') ||
                event.target.closest('.nav-secondary') ||
                isTouchOnCube(event)) {
                // Allow touch events on these elements
                event.stopPropagation(); // Prevent page scrolling
                touchEndX = event.touches[0].screenX;
                handleGesture();
            } else {
                // Prevent default behavior for other elements
                event.preventDefault();
            }
        },
        touchEnd: (event) => {
            // Check if the touch is on a button or a cube
            if (event.target.closest('#nav') || 
                event.target.closest('.btn') ||
                event.target.closest('.nav-secondary') ||
                isTouchOnCube(event)) {
                // Allow touch events on these elements
                event.stopPropagation(); // Prevent page scrolling
                touchEndX = event.changedTouches[0].screenX;
                handleGesture();
                // Trigger click event for cube interaction
                onClick(event.changedTouches[0]);
            } else {
                // Prevent default behavior for other elements
                event.preventDefault();
            }
        }
    };

    // Helper function to check if the touch is on a cube
    function isTouchOnCube(event) {
        const rect = renderer.domElement.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects([cube1.cube, cube2.cube]);
        return intersects.length > 0;
    }

    if (isMobile) {
        renderer.domElement.addEventListener('touchstart', eventHandlers.touchStart, { passive: false });
        renderer.domElement.addEventListener('touchmove', eventHandlers.touchMove, { passive: false });
        renderer.domElement.addEventListener('touchend', eventHandlers.touchEnd, { passive: false });
    }

    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
    }
    window.addEventListener('resize', onWindowResize);
    function resizeRendererToDisplaySize() {
        return false;
    }

    // Add fade animation state
    const fadeState = {
        opacity: 0,
        targetOpacity: 1,
        fadeSpeed: 0.01
    };

    // Make materials transparent for fading
    const makeTransparent = (materials) => {
        materials.forEach(material => {
            material.transparent = true;
            material.opacity = 0;
        });
    };

    // Apply transparency to all cube materials
    makeTransparent(materials_1);
    makeTransparent(materials_2);

    // Create update method for animation
    function update() {
        // Handle rotations
        if (globalRotationEnabled) {
            if (cube1.cube.userData.rotating) {
                cube1.cube.rotation.x += 0.015;
                cube1.cube.rotation.y -= 0.01;
                cube1.cube.rotation.z += 0.019;
            }
            if (cube2.cube.userData.rotating) {
                cube2.cube.rotation.x -= 0.019;
                cube2.cube.rotation.y += 0.015;
                cube2.cube.rotation.z -= 0.01;
            }
        }

        // Handle fade in
        if (fadeState.opacity < fadeState.targetOpacity) {
            fadeState.opacity = Math.min(fadeState.opacity + fadeState.fadeSpeed, fadeState.targetOpacity);

            materials_1.forEach(material => {
                material.opacity = fadeState.opacity;
            });

            materials_2.forEach(material => {
                material.opacity = fadeState.opacity;
            });
        }

        // Update controls
        controls.update();
    }

    // Create cleanup function
    const cleanup = () => {
        try {
            // Remove event listeners
            renderer.domElement.removeEventListener('click', onClick);
            window.removeEventListener('resize', onWindowResize);

            if (isMobile) {
                renderer.domElement.removeEventListener('touchstart', eventHandlers.touchStart);
                renderer.domElement.removeEventListener('touchmove', eventHandlers.touchMove);
                renderer.domElement.removeEventListener('touchend', eventHandlers.touchEnd);
            }

            // Dispose of stored textures
            textures.forEach(texture => {
                if (texture) texture.dispose();
            });

            // Dispose of geometries and materials
            if (geometry) {
                geometry.dispose();
            }

            if (materials_1) {
                materials_1.forEach(material => {
                    if (material.map) material.map.dispose();
                    material.dispose();
                });
            }

            if (materials_2) {
                materials_2.forEach(material => {
                    if (material.map) material.map.dispose();
                    material.dispose();
                });
            }

            // Remove meshes and clean up scene
            if (logoScene) {
                if (cube1 && cube1.cube) logoScene.remove(cube1.cube);
                if (cube2 && cube2.cube) logoScene.remove(cube2.cube);
                if (cubeGroup) logoScene.remove(cubeGroup);
                if (light1) logoScene.remove(light1);
                if (light2) logoScene.remove(light2);
                logoScene.clear();
            }

            // Dispose of controls
            if (controls) {
                controls.dispose();
            }

        } catch (error) {
            console.warn('Error during cleanup:', error);
        }
    };

    logoScene.cleanup = cleanup; // Attach the cleanup function to the group

    // Return the necessary objects including the update function
    return {
        scene: logoScene,
        camera: camera,
        controls: controls, // If you have OrbitControls
        update: update,
        fadeIn: () => {
            fadeState.opacity = 0;
            fadeState.targetOpacity = 1;
        },
        fadeOut: () => {
            fadeState.opacity = 1;
            fadeState.targetOpacity = 0;
        }
    };
}

export default buildLogoCubes;

