const pageTitle = "Art";
const description = [
    {
        subHead: `Exhibits + Shows`,
        body: `Drawing inspiration from the mundane, infusing humor and playfulness into ordinary subjects. Exploring the boundaries of object and viewer.`
    }
];
const buttons = [
    {
        id: "btn-flimsy",
        class: "btn",
        text: "  Flimsy  ",
        href: "/art/flimsy-currency",
    },
    {
        id: "btn-imgs",
        class: "btn",
        text: "  The.imgs  ",
        href: "/art/the-imgs",
    },
    {
        id: "btn-tv-stills",
        class: "btn",
        text: "  TV Stills  ",
        href: "/art/tv-stills",
    },
    {
        id: "btn-noesis",
        class: "btn",
        text: "  Noesis/  ",
        href: "/art/noesis-landscape",
    },
];
export { pageTitle, description, buttons };

