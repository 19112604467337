import * as THREE from 'three';
import { Text } from 'troika-three-text';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { buttons } from '../data/dataWeb';
import gridliteFont from '../assets/fonts/GridlitePEVF-All.ttf';

let instance = null;
let controls = null;

export function initWebCarousel(renderer, parentScene, router) {
    if (instance) return instance;

    const group = new THREE.Group();

    const camera = new THREE.PerspectiveCamera(
        120, window.innerWidth / window.innerHeight, 0.0000001, 10000000
    );
    group.rotation.x = THREE.MathUtils.degToRad(10);
    camera.position.z = 10;
    camera.rotateOnAxis(new THREE.Vector3(1, 0, 0), THREE.MathUtils.degToRad(70));
    group.position.y = -8;

    // Create spotlight
    const spotlight = new THREE.SpotLight(0xffffff, 500);
    spotlight.position.set(0, 0, 15);
    spotlight.target.position.set(0, 0, -5);
    group.add(spotlight);
    group.add(spotlight.target);

    const cardGroup = new THREE.Group();
    group.add(cardGroup);

    // Create text objects
    const radius = 20;
    const textObjects = [];
    const materials = [];
    const geometries = [];

    buttons.forEach((project, index) => {
        const angle = (index / buttons.length) * Math.PI * 2;
        const x = Math.cos(angle) * radius;
        const z = Math.sin(angle) * radius;

        const text = new Text();
        text.text = project.text;
        text.fontSize = 5;
        text.anchorX = 'center';
        text.anchorY = 'middle';
        text.position.set(x, 0, z);
        text.rotation.set(0, Math.atan2(camera.position.x - x, camera.position.z - z), 0);
        text.font = gridliteFont;
        text.isText = true;
        text.userData = { URL: project.url, href: project.href };
        text.sync();

        const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
        text.material = material;
        materials.push(material);

        text.layers.set(4); // Change from 5 to 4
        textObjects.push(text);
        cardGroup.add(text);
    });

    // Store initial auto-rotate speed for resets
    const initialAutoRotateSpeed = 2.0;
    
    // Setup touch and wheel interactions for rotation speed
    let touchStartX = 0;
    let touchEndX = 0;
    let touchStartY = 0;
    let touchEndY = 0;
    
    function handleGesture() {
        if (!controls) return;
        
        if (touchEndX < touchStartX) controls.autoRotateSpeed += 0.1;
        if (touchEndX > touchStartX) controls.autoRotateSpeed -= 0.1;
        clearTimeout(window.resetAutoRotateSpeedTimeout);
        window.resetAutoRotateSpeedTimeout = setTimeout(() => {
            if (controls) controls.autoRotateSpeed = initialAutoRotateSpeed;
        }, 200);
    }
    
    function onTouchStart(event) {
        // Store position for both swipe detection and potential tap
        touchStartX = event.changedTouches[0].screenX;
        
        // Also store Y position to detect if it's a tap or a swipe
        touchStartY = event.changedTouches[0].screenY;
        
        // Update the pointer position for raycasting
        pointer.x = (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1;
        pointer.y = -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1;
    }
    
    function onTouchEnd(event) {
        touchEndX = event.changedTouches[0].screenX;
        touchEndY = event.changedTouches[0].screenY;
        
        // Update pointer position for final touch position
        pointer.x = (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1;
        pointer.y = -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1;
        
        // Calculate movement distance
        const moveX = Math.abs(touchEndX - touchStartX);
        const moveY = Math.abs(touchEndY - touchStartY);
        
        // If movement is small enough, treat as a tap/click
        if (moveX < 10 && moveY < 10) {
            handleTap(event);
        } else {
            // Otherwise it's a swipe gesture
            handleGesture();
        }
    }

    // Add this function to handle taps like clicks
    function handleTap(event) {
        // Use the same raycasting logic as click but with touch coordinates
        raycaster.layers.set(4); // Change from 5 to 4
        raycaster.setFromCamera(pointer, camera);
        const intersects = raycaster.intersectObjects(textObjects);
        console.log('Touch intersects:', intersects);

        if (intersects.length > 0) {
            const intersectedObject = intersects[0].object;
            if (intersectedObject.isText) {
                // Prevent default only if we actually hit something
                event.preventDefault();
                
                const text = intersectedObject.text.trim();
                const button = buttons.find(btn => btn.text.trim() === text);
                if (button && button.href) {
                    console.log('Navigating to:', button.href);
                    router.push(button.href);
                }
            }
        }
    }
    
    function onWheel(event) {
        if (!controls) return;
        
        if (event.deltaY > 0) controls.autoRotateSpeed += 0.1;
        else controls.autoRotateSpeed -= 0.1;
        
        clearTimeout(window.resetAutoRotateSpeedTimeout);
        window.resetAutoRotateSpeedTimeout = setTimeout(() => {
            if (controls) controls.autoRotateSpeed = initialAutoRotateSpeed;
        }, 200);
    }

    // Setup raycaster for interaction
    const raycaster = new THREE.Raycaster();
    raycaster.layers.set(4); // Change from 5 to 4
    const pointer = new THREE.Vector2(0, -1);
    let currentIntersected = null;
    
    // Color management for hover effects
    const lastColors = [];
    function getRandomColor() {
        const colors = [
            0x00FF7F, 0xDFFF00, 0xFFE4C4, 0x0000FF, 0xFFA07A, 
            0x9ACD32, 0x00ffff, 0xFFA500, 0xFF55D3, 0x1E90FF, 0xDAA520
        ];
        let newColor = null;
        do {
            newColor = colors[Math.floor(Math.random() * colors.length)];
        } while (lastColors.includes(newColor));
        
        lastColors.push(newColor);
        if (lastColors.length > 5) {
            lastColors.shift();
        }
        return newColor;
    }
    
    // Setup scroll effects
    function onScroll() {
        const scrollY = window.scrollY;
        cardGroup.children.forEach(child => {
            if (child.isText) {
                child.scale.set(1 + scrollY * 0.001, 1 + scrollY * 0.001, 1);
                child.rotation.z = scrollY * 0.01;
            }
        });
    }

    // Add click handler
    function onClick(event) {
        // Calculate pointer position
        const x = (event.clientX / window.innerWidth) * 2 - 1;
        const y = -(event.clientY / window.innerHeight) * 2 + 1;
        pointer.x = x;
        pointer.y = y;

        // Check for intersections
        raycaster.layers.set(4); // Change from 5 to 4
        raycaster.setFromCamera(pointer, camera);
        const intersects = raycaster.intersectObjects(textObjects);
        console.log('Click intersects:', intersects);

        if (intersects.length > 0) {
            // Only prevent default if we hit something
            event.preventDefault();
            
            const intersectedObject = intersects[0].object;
            if (intersectedObject.isText) {
                const text = intersectedObject.text.trim();
                const button = buttons.find(btn => btn.text.trim() === text);
                if (button && button.href) {
                    console.log('Navigating to:', button.href);
                    router.push(button.href);
                }
            }
        }
    }

    // Add event listeners
    window.addEventListener('click', onClick);
    window.addEventListener('pointermove', (event) => {
        pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
        pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
    });
    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchend', onTouchEnd);
    window.addEventListener('wheel', onWheel);
    window.addEventListener('scroll', onScroll);

    // Animation function
    function animate() {
        // Update controls if they exist
        if (controls) {
            controls.update();
        }
        
        // Handle hover effects with raycaster
        raycaster.setFromCamera(pointer, camera);
        const intersects = raycaster.intersectObjects(textObjects);
        
        // Reset previous hover state if needed
        if (currentIntersected && !intersects.find(intersect => intersect.object === currentIntersected)) {
            if (currentIntersected.material && currentIntersected.material.color) {
                currentIntersected.material.color.set(0xffffff);
            }
            currentIntersected = null;
        }
        
        // Apply new hover state if needed
        if (intersects.length > 0) {
            if (currentIntersected !== intersects[0].object) {
                currentIntersected = intersects[0].object;
                if (currentIntersected && currentIntersected.material && currentIntersected.material.color) {
                    currentIntersected.material.color.set(getRandomColor());
                }
            }
        }
    }
    
    // Function to set up controls when renderer is available
    function setupControls(rendererInstance) {
        if (!rendererInstance || !rendererInstance.domElement) {
            console.warn('Cannot setup controls: renderer or domElement is missing');
            return;
        }
        
        controls = new OrbitControls(camera, rendererInstance.domElement);
        controls.enableDamping = true;
        controls.autoRotate = true;
        controls.autoRotateSpeed = initialAutoRotateSpeed;
        controls.dampingFactor = 0.25;
        controls.screenSpacePanning = false;
        controls.maxPolarAngle = Math.PI / 2;
        controls.minPolarAngle = Math.PI / 2;
        controls.minAzimuthAngle = -Infinity;
        controls.maxAzimuthAngle = Infinity;
        controls.enableZoom = false;
        controls.enablePan = false;
        
        console.log('OrbitControls initialized for web carousel');
    }

    // Create instance
    instance = {
        group,
        camera,
        materials,
        geometries,
        update: animate,
        setupControls,
        cleanup() {
            // Remove all event listeners
            window.removeEventListener('click', onClick);
            window.removeEventListener('touchstart', onTouchStart);
            window.removeEventListener('touchend', onTouchEnd);
            window.removeEventListener('wheel', onWheel);
            window.removeEventListener('scroll', onScroll);
            
            // Clear any pending timeouts
            clearTimeout(window.resetAutoRotateSpeedTimeout);
            
            // Dispose controls
            if (controls) {
                controls.dispose();
                controls = null;
            }
            
            // Dispose text objects
            textObjects.forEach(text => {
                if (text.dispose) text.dispose();
            });
            
            // Dispose materials and geometries
            materials.forEach(m => m.dispose());
            geometries.forEach(g => g.dispose());
            
            // Reset instance
            instance = null;
        }
    };

    // Apply fade-in effect
    materials.forEach(material => {
        material.opacity = 0;
        material.transparent = true;
    });
    
    let opacity = 0;
    const fadeIn = () => {
        opacity += 0.036;
        if (opacity <= 1) {
            materials.forEach(material => {
                material.opacity = opacity;
            });
            setTimeout(fadeIn, 30);
        }
    };
    fadeIn();
    
    // Initialize controls if renderer is available
    if (renderer && renderer.domElement) {
        setupControls(renderer);
    }

    return instance;
}

// Add this to your cleanup function - it will help you debug on mobile
window.debugTouch = function(msg) {
    const debugElement = document.getElementById('debug-touch') || (() => {
        const el = document.createElement('div');
        el.id = 'debug-touch';
        el.style.position = 'fixed';
        el.style.bottom = '10px';
        el.style.left = '10px';
        el.style.background = 'rgba(0,0,0,0.7)';
        el.style.color = 'white';
        el.style.padding = '10px';
        el.style.zIndex = 9999;
        document.body.appendChild(el);
        return el;
    })();
    
    debugElement.innerHTML = msg;
};

export default function buildWebCarousel(router) {
    return initWebCarousel(null, null, router);
}
