import { useRouter } from 'vue-router';
import * as THREE from "three";
import { Text } from "troika-three-text";
import { buttons } from "../data/dataArt";
import gridliteFont from "../assets/fonts/GridlitePEVF-All.ttf";

function buildArtCarousel(router) {
    const artScene = new THREE.Group(); // Create the artScene group

    let camera = new THREE.PerspectiveCamera(
        50,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
    );
    camera.position.z = 5;
    camera.lookAt(0, 0.3, 0);

    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2(0, -1);

    let currentTextIndex = 0;
    let textObject = createTextObject(-100);
    artScene.add(textObject);
    let isScrolling = false;
    let scrollTimeout;

    // Store event listeners for cleanup
    const eventListeners = {
        wheel: null,
        pointermove: null,
        click: null,
        mousemove: null,
        resize: null
    };

    // Modify event listener attachments
    eventListeners.wheel = (event) => {
        isScrolling = true;
        clearTimeout(scrollTimeout);
        textObject.position.z += event.deltaY * 0.01;
        if (textObject.position.z >= 0) {
            artScene.remove(textObject);
            currentTextIndex = (currentTextIndex + 1) % buttons.length;
            textObject = createTextObject(-100);
            artScene.add(textObject);
        } else if (textObject.position.z <= -101) {
            artScene.remove(textObject);
            currentTextIndex =
                (currentTextIndex - 1 + buttons.length) % buttons.length;
            textObject = createTextObject(1);
            artScene.add(textObject);
        }
        scrollTimeout = setTimeout(() => {
            isScrolling = false;
        }, 100);
    };
    window.addEventListener("wheel", eventListeners.wheel);

    eventListeners.pointermove = onPointerMove;
    eventListeners.click = onClick;
    eventListeners.resize = onWindowResize;
    eventListeners.mousemove = (event) => {
        pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
        pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
        raycaster.setFromCamera(pointer, camera);
        const intersects = raycaster.intersectObjects([textObject]);
        if (intersects.length > 0) {
            if (!isMouseOverText) {
                textObject.material.color.set(getRandomColor());
                isMouseOverText = true;
            }
        } else {
            if (isMouseOverText) {
                textObject.material.color.set(0xffffff);
                isMouseOverText = false;
            }
        }
    };

    window.addEventListener("pointermove", eventListeners.pointermove);
    window.addEventListener("click", eventListeners.click);
    window.addEventListener("resize", eventListeners.resize);
    window.addEventListener("mousemove", eventListeners.mousemove);

    function createTextObject(zPosition) {
        const text = new Text();
        if (buttons[currentTextIndex]) {
            text.text = buttons[currentTextIndex].text;
            text.isText = true; // Ensure isText property is set
        } else {
            console.error("Invalid currentTextIndex or buttons is undefined");
        }
        text.fontSize = 1;
        text.color = 0xffffff;
        text.position.set(0, 0, zPosition);
        text.anchorX = "center";
        text.anchorY = "middle";
        text.font = gridliteFont;
        text.sync();
        return text;
    }

    function onPointerMove(event) {
        pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
        pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
    }

    function onClick() {
        raycaster.setFromCamera(pointer, camera);
        const intersects = raycaster.intersectObjects([textObject]);
        if (intersects.length > 0) {
            const intersectedObject = intersects[0].object;
            if (intersectedObject.isText) {
                const text = intersectedObject.text.trim();
                const button = buttons.find(btn => btn.text.trim() === text);
                if (button && button.href) {
                    // console.log('Navigating to:', button.href);
                    // Navigate using router.push with route object
                    router.push({
                        path: button.href,
                        replace: false
                    }).catch(err => {
                        console.error('Navigation failed:', err);
                        // Handle navigation error if needed
                    });
                } else {
                    console.error('Button or href not found for text:', text);
                }
            }
        }
    }

    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        console.log("Window resized");
    }

    let isMouseOverText = false;

    let animationFrameId = null;

    function animate() {
        animationFrameId = requestAnimationFrame(animate);
        if (!isScrolling) {
            textObject.position.z -= 0.05; // Move text away from the camera

            if (textObject.position.z <= -10) {
                // Adjust condition for removing text
                artScene.remove(textObject);
                currentTextIndex = (currentTextIndex + 1) % buttons.length;
                textObject = createTextObject(-0.01); // Adjust initial position
                artScene.add(textObject);
            }
        }
    }

    animate();

    let lastColor = null;
    function getRandomColor() {
        const colors = [0xFF0000, 0x00FF7F, 0xFFFF00, 0x0000FF, 0x00FF00, 0xEE82EE, 0xFFA500, 0xFF00FF, 0x00FFFF, 0xFFD700, 0xFF4500, 0xADFF2F];
        let newColor = null;
        do {
            newColor = colors[Math.floor(Math.random() * colors.length)];
        } while (newColor === lastColor);
        lastColor = newColor;
        return newColor;
    }

    // Create cleanup function
    const cleanup = () => {
        try {
            // Cancel any pending animations
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
                animationFrameId = null;
            }

            // Clean up event listeners
            if (eventListeners) {
                Object.entries(eventListeners).forEach(([event, handler]) => {
                    if (handler) {
                        window.removeEventListener(event, handler);
                    }
                });
            }

            // Dispose Three.js objects
            artScene.traverse((object) => {
                    if (object.geometry) {
                        object.geometry.dispose();
                    }
                    if (object.material) {
                        if (Array.isArray(object.material)) {
                            object.material.forEach(material => material.dispose());
                        } else {
                            object.material.dispose();
                        }
                    }
                    if (object.texture) {
                        object.texture.dispose();
                    }
                });

            // Null references in a safe way
            if (textObject) {
                textObject.dispose?.();
                textObject = null;
            }

        } catch (error) {
            console.warn('Cleanup error in art carousel:', error);
        }
    };

    artScene.cleanup = cleanup; // Attach the cleanup function to the group

    return { scene: artScene, camera }; // Return the group and camera
}

export default buildArtCarousel;

