import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js"; // Update path if needed
import "./styles/index.css";
import wavyHover from "@/shaders/wavyHover";
import { loadFonts } from "./utils/fontLoader";

loadFonts().then((success) => {
  if (!success) {
    console.warn("Font loading failed, falling back to system fonts");
  }
});

const app = createApp(App);

// Make the app instance globally available if needed
window.app = app;

// Register the directive globally
app.directive("wavy-hover", wavyHover);

app.use(router);
app.mount("#app");
