const pageTitle = `UX/UI`;
const description = [
    {
        subHead: `Product Design`,
        body: `Creating interfaces and experiences, advocating for design systems. Fun, forward-thinking, and unapologetically rallied for the end-user.`
    }
];
const buttons = [
    {
        id: "btn-neoera",
        class: "btn",
        text: "  Neoera  ",
        href: "/ux-ui/neoera",
    },
    {
        id: "btn-yellow",
        class: "btn",
        text: "  Yellow  ",
        href: "/ux-ui/yellow",
    },
    {
        id: "btn-hca",
        class: "btn",
        text: "  HCA  ",
        href: "/ux-ui/hca",
    },
    {
        id: "btn-spectrum",
        class: "btn",
        text: "  Spectrum  ",
        href: "/ux-ui/spectrum",
    },
    {
        id: "btn-starz",
        class: "btn",
        text: "  Starz  ",
        href: "/ux-ui/starz",
    }
];



export { pageTitle, description, buttons };