<script setup>
import { onMounted, onUnmounted, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import Loading from "./components/Loading.vue";
import ThreeView from "./views/ThreeView.vue";

const router = useRouter();
const isLoading = ref(true);
const isNavigating = ref(false);

// Add navigation event handlers
router.beforeEach((to, from, next) => {
  // Skip loading screen for splash to home transition
  if (from.path === "/splash" && to.path === "/") {
    next();
    return;
  }

  // Show loading for other transitions
  isNavigating.value = true;
  next();
});

router.afterEach(() => {
  // Hide loading after navigation completes
  setTimeout(() => {
    isNavigating.value = false;
  }, 400); // Short delay to ensure smooth transition
});

const initApp = async () => {
  try {
    console.log("Initialization started, loading:", isLoading.value);
    await nextTick();
    await document.fonts.ready;
    
    // Set loading to false when initialization completes
    isLoading.value = false;
    console.log("Initialization completed, loading:", isLoading.value);
  } catch (error) {
    console.error("Initialization error:", error);
    isLoading.value = false;
  }
};

onMounted(initApp);
</script>

<template>
  <div id="app-wrapper" :class="{ 'is-loading': isLoading }">
    <!-- ThreeView component for the cosmos background -->
    <ThreeView />
    
    <div class="loading-overlay" v-if="isLoading">
      <!-- Loading animation here -->
      <!-- <div class="loader"></div> -->
    </div>
    
    <main>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="$route.fullPath" />
        </transition>
      </router-view>
    </main>
    
    <Loading v-show="isLoading || isNavigating" />
  </div>
</template>

<style>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
  position: absolute;
  width: 100%;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
