<template>
  <div class="loader" v-if="show">
    <div class="stack-container">
      <div class="number-animation">
        <span class="number" v-for="(number, index) in allNumbers" :key="index">
          {{ number }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import "../styles/index.css";

export default {
  name: "Loading",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allNumbers: Array.from({ length: 10 }, (_, i) =>
        String.fromCharCode(0x0039 - i)
      ),
    };
  },
};
</script>

<style scoped>
.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-family: "Gridlite", monospace;
  font-variation-settings: "wght" 1000, "BACK" 75, "RECT" 75, "ELSH" 4;
  font-size: 6.8rem;
  font-feature-settings: "salt", "ss01";
  pointer-events: none;
  z-index: 10000;
  /* background-color: rgba(0, 0, 0, 0.7); */
}

.stack-container {
  position: relative;
  height: 6rem;
  width: 6rem;
  overflow: hidden;
}

.number-animation {
  display: flex;
  flex-direction: column;
  position: relative;
  animation: cycle 4s infinite steps(1);
}

.number {
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Animation to cycle through numbers */
@keyframes cycle {
  0%,
  20% {
    transform: translateY(0);
    color: #ff0000; /* Red */
  }
  4% {
    color: #ffffff; /* White */
  }
  8% {
    color: #00ff7f; /* Spring Green */
  }
  12% {
    color: #ffffff; /* White */
  }
  16% {
    color: #ffff00; /* Yellow */
  }

  20%,
  40% {
    transform: translateY(-6rem);
    color: #ffffff; /* White */
  }
  24% {
    color: #0000ff; /* Blue */
  }
  28% {
    color: #ffffff; /* White */
  }
  32% {
    color: #00ff00; /* Green */
  }
  36% {
    color: #ffffff; /* White */
  }

  40%,
  60% {
    transform: translateY(-12rem);
    color: #ee82ee; /* Violet */
  }
  44% {
    color: #ffffff; /* White */
  }
  48% {
    color: #ffa500; /* Orange */
  }
  52% {
    color: #ffffff; /* White */
  }
  56% {
    color: #ff00ff; /* Magenta */
  }

  60%,
  80% {
    transform: translateY(-18rem);
    color: #ffffff; /* White */
  }
  64% {
    color: #00ffff; /* Cyan */
  }
  68% {
    color: #ffffff; /* White */
  }
  72% {
    color: #ffd700; /* Gold */
  }
  76% {
    color: #ffffff; /* White */
  }

  80%,
  100% {
    transform: translateY(-24rem);
    color: #ff4500; /* Orange Red */
  }
  84% {
    color: #ffffff; /* White */
  }
  88% {
    color: #adff2f; /* Green Yellow */
  }
  92% {
    color: #ffffff; /* White */
  }
  96% {
    color: #ff0000; /* Back to Red */
  }
}
</style>
