<template>
  <div class="three-container">
    <canvas ref="threeCanvas" class="three-canvas"></canvas>
    <slot></slot> <!-- Allow content to be placed on top of the canvas -->
  </div>
</template>

<script>
import * as THREE from 'three';
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { initCosmos } from '../models/theCosmos.js';
import { initSmileyFriend, disposeSmileyFace } from '../models/smileyFriend.js';
import buildLogoCubes from '../models/logoCubes.js';
import buildArtCarousel from '../models/artCarousel.js';
import buildWebCarousel from '../models/webCarousel.js';

export default {
  name: 'ThreeView',
  setup() {
    // References
    const threeCanvas = ref(null);
    const route = useRoute();
    const router = useRouter();
    
    // Three.js variables
    let renderer, scene, animationFrameId;
    let cosmos = null;
    let smileyFace = null;
    let logoCubes = null;
    let artCarousel = null;
    let webCarousel = null;
    
    // Create separate scene groups
    const cosmosGroup = new THREE.Group();
    const smileyGroup = new THREE.Group();
    const logoGroup = new THREE.Group();
    const artGroup = new THREE.Group();
    const webGroup = new THREE.Group();
    
    // Track active pages
    const isHomePage = ref(route.path === '/' || route.path === '/home');
    const isLogoPage = ref(route.path === '/logo');
    const isInfoPage = ref(route.path === '/info');
    const isArtPage = ref(route.path === '/art');
    const isWebPage = ref(route.path === '/ux-ui');

    // Define layers
    const COSMOS_LAYER = 0;  // Default layer
    const SMILEY_LAYER = 1;  // Custom layer for smiley face
    const LOGO_LAYER = 2;    // Layer for logo cubes
    const ART_LAYER = 3;     // Layer for art carousel
    const WEB_LAYER = 4;     // Layer for web carousel
    
    // Initialize Three.js scene
    const initThree = async () => {
      if (!threeCanvas.value) return;

      // Create renderer
      renderer = new THREE.WebGLRenderer({
        canvas: threeCanvas.value,
        antialias: true,
        alpha: true,
      });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      renderer.autoClear = false; // Important for multiple renders

      // Create scene and add groups
      scene = new THREE.Scene();
      scene.add(cosmosGroup);
      scene.add(smileyGroup);
      scene.add(logoGroup);
      scene.add(artGroup);
      scene.add(webGroup);
      
      try {
        // Initialize cosmos background - this always stays
        cosmos = await initCosmos();
        cosmosGroup.add(cosmos.group);
        
        // Set cosmos to use default layer only
        cosmos.group.traverse((object) => {
          object.layers.set(COSMOS_LAYER);
        });

        // Initialize components based on current route
        if (isHomePage.value) {
          await loadSmileyFace();
        }
        
        if (isLogoPage.value) {
          await loadLogoCubes();
        }
        
        if (isArtPage.value) {
          await loadArtCarousel();
        }
        
        if (isWebPage.value) {
          await loadWebCarousel();
        }

        // Start animation loop
        animate();
      } catch (error) {
        console.error('Failed to initialize scene:', error);
      }
    };

    // Load smiley face
    const loadSmileyFace = async () => {
      try {
        if (!renderer || smileyFace) return;
        
        console.log('Loading smiley face for home page');
        smileyFace = await initSmileyFriend(renderer, scene);
        
        if (smileyFace && smileyFace.group) {
          console.log('SmileyFace loaded successfully!');
          
          // Set the position
          smileyFace.group.position.set(0, 0, 0);
          
          // Add to our group
          smileyGroup.add(smileyFace.group);
          smileyGroup.visible = true;
          
          // Set smiley face to use the smiley layer
          smileyFace.group.traverse((object) => {
            object.layers.set(SMILEY_LAYER);
          });
          
          // Configure smiley camera to see only the smiley layer
          smileyFace.camera.layers.set(SMILEY_LAYER);
        }
      } catch (error) {
        console.error('Failed to load smiley face:', error);
      }
    };

    // Load logo cubes
    const loadLogoCubes = async () => {
      try {
        if (!renderer || logoCubes) {
          console.log('Skipping logo cubes load: renderer exists?', !!renderer, 'logoCubes already exists?', !!logoCubes);
          return;
        }
        
        console.log('Loading logo cubes for logo page');
        
        // Define isMobile for logoCubes
        const isMobile = window.innerWidth < 768;
        window.isMobile = isMobile;
        console.log('Mobile device detected:', isMobile);
        
        // Build the logo cubes
        console.log('Calling buildLogoCubes function...');
        logoCubes = buildLogoCubes(renderer);
        console.log('LogoCubes build result:', logoCubes);
        
        if (logoCubes && logoCubes.scene) {
          console.log('Logo cubes loaded successfully!', {
            hasScene: !!logoCubes.scene,
            hasCamera: !!logoCubes.camera,
            hasControls: !!logoCubes.controls,
            hasUpdate: !!logoCubes.update,
            children: logoCubes.scene.children.length
          });
          
          // Trigger fade-in if the method exists
          if (logoCubes.fadeIn) {
            logoCubes.fadeIn();
            console.log('Starting logo cubes fade-in animation');
          }
          
          // Add to our group
          logoGroup.add(logoCubes.scene);
          logoGroup.visible = true;
          console.log('Added logoCubes to logoGroup, visibility:', logoGroup.visible);
          
          // Set logo cubes to use the logo layer
          logoCubes.scene.traverse((object) => {
            object.layers.set(LOGO_LAYER);
          });
          console.log('Set all logoCube objects to use layer:', LOGO_LAYER);
          
          // Configure logo camera to see only the logo layer
          logoCubes.camera.layers.set(LOGO_LAYER);
          console.log('Logo camera configured to see only logo layer');
        } else {
          console.error('Logo cubes failed to load correctly - scene is missing', logoCubes);
        }
      } catch (error) {
        console.error('Failed to load logo cubes:', error);
      }
    };

    // Load art carousel 
    const loadArtCarousel = async () => {
      try {
        if (!renderer || artCarousel) {
          console.log('Skipping art carousel load: renderer exists?', !!renderer, 'artCarousel already exists?', !!artCarousel);
          return;
        }
        
        console.log('Loading art carousel for art page');
        
        // Build the art carousel
        console.log('Calling buildArtCarousel function...');
        artCarousel = buildArtCarousel(router);
        console.log('Art carousel build result:', artCarousel);
        
        if (artCarousel && artCarousel.scene) {
          console.log('Art carousel loaded successfully!', {
            hasScene: !!artCarousel.scene,
            hasCamera: !!artCarousel.camera,
            children: artCarousel.scene.children.length
          });
          
          // Add to our group
          artGroup.add(artCarousel.scene);
          artGroup.visible = true;
          console.log('Added artCarousel to artGroup, visibility:', artGroup.visible);
          
          // Set art carousel to use the art layer
          artCarousel.scene.traverse((object) => {
            object.layers.set(ART_LAYER);
          });
          console.log('Set all artCarousel objects to use layer:', ART_LAYER);
          
          // Configure art camera to see only the art layer
          artCarousel.camera.layers.set(ART_LAYER);
          console.log('Art camera configured to see only art layer');
        } else {
          console.error('Art carousel failed to load correctly - scene is missing', artCarousel);
        }
      } catch (error) {
        console.error('Failed to load art carousel:', error);
      }
    };
    
    // Load web carousel 
    const loadWebCarousel = async () => {
      try {
        if (!renderer || webCarousel) {
          console.log('Skipping web carousel load: renderer exists?', !!renderer, 'webCarousel already exists?', !!webCarousel);
          return;
        }
        
        console.log('Loading web carousel for web page');
        
        // Build the web carousel
        console.log('Calling buildWebCarousel function...');
        webCarousel = buildWebCarousel(router);
        console.log('Web carousel build result:', webCarousel);
        
        if (webCarousel && webCarousel.group) {
          console.log('Web carousel loaded successfully!', {
            hasGroup: !!webCarousel.group,
            hasCamera: !!webCarousel.camera,
            hasControls: !!webCarousel.controls,
            hasUpdate: !!webCarousel.update
          });
          
          // Set up controls with the renderer
          if (webCarousel.setupControls) {
            webCarousel.setupControls(renderer);
            console.log('Web carousel controls initialized with renderer');
          }
          
          // Add to our group
          webGroup.add(webCarousel.group);
          webGroup.visible = true;
          console.log('Added webCarousel to webGroup, visibility:', webGroup.visible);
          
          // Set web carousel to use the web layer
          webCarousel.group.traverse((object) => {
            object.layers.set(WEB_LAYER);
          });
          console.log('Set all webCarousel objects to use layer:', WEB_LAYER);
          
          // Configure web camera to see only the web layer
          webCarousel.camera.layers.set(WEB_LAYER);
          console.log('Web camera configured to see only web layer');
        } else {
          console.error('Web carousel failed to load correctly - group is missing', webCarousel);
        }
      } catch (error) {
        console.error('Failed to load web carousel:', error);
      }
    };
    
    // Dispose smiley face but keep cosmos
    const unloadSmileyFace = () => {
      if (smileyFace) {
        console.log('Disposing smiley face');
        smileyGroup.remove(smileyFace.group);
        disposeSmileyFace();
        smileyFace = null;
        smileyGroup.visible = false;
      }
    };
    
    // Dispose logo cubes but keep cosmos
    const unloadLogoCubes = () => {
      if (logoCubes) {
        console.log('Disposing logo cubes. Current state:', {
          hasScene: !!logoCubes.scene,
          hasCamera: !!logoCubes.camera,
          hasControls: !!logoCubes.controls
        });
        
        // Optional: You could add a fade-out with a delay before removing
        if (logoCubes.fadeOut) {
          logoCubes.fadeOut();
          
          // Give it time to fade out before removing
          setTimeout(() => {
            // Continue with removal
            completeLogoCubeRemoval();
          }, 500); // 500ms for fade-out
        } else {
          // No fade capability, remove immediately
          completeLogoCubeRemoval();
        }
      } else {
        console.log('No logoCubes to dispose, already null');
      }
    };

    // Helper function to complete the removal process
    const completeLogoCubeRemoval = () => {
      // Call the cleanup function attached to the scene
      if (typeof logoCubes.scene.cleanup === 'function') {
        console.log('Found cleanup function on logoCubes.scene, calling it');
        logoCubes.scene.cleanup();
      } else {
        console.log('No cleanup function found on logoCubes.scene');
      }
      
      logoGroup.remove(logoCubes.scene);
      console.log('Removed logoCubes.scene from logoGroup');
      
      logoCubes = null;
      logoGroup.visible = false;
      console.log('Set logoCubes to null and logoGroup visibility to false');
    };

    // Dispose art carousel but keep cosmos
    const unloadArtCarousel = () => {
      if (artCarousel) {
        console.log('Disposing art carousel');
        
        // Call the cleanup function attached to the scene
        if (typeof artCarousel.scene.cleanup === 'function') {
          console.log('Found cleanup function on artCarousel.scene, calling it');
          artCarousel.scene.cleanup();
        } else {
          console.log('No cleanup function found on artCarousel.scene');
        }
        
        artGroup.remove(artCarousel.scene);
        console.log('Removed artCarousel.scene from artGroup');
        
        artCarousel = null;
        artGroup.visible = false;
        console.log('Set artCarousel to null and artGroup visibility to false');
      } else {
        console.log('No artCarousel to dispose, already null');
      }
    };
    
    // Dispose web carousel but keep cosmos
    const unloadWebCarousel = () => {
      if (webCarousel) {
        console.log('Disposing web carousel');
        
        // Call the cleanup function 
        if (typeof webCarousel.cleanup === 'function') {
          console.log('Found cleanup function on webCarousel, calling it');
          webCarousel.cleanup();
        } else {
          console.log('No cleanup function found on webCarousel');
        }
        
        webGroup.remove(webCarousel.group);
        console.log('Removed webCarousel.group from webGroup');
        
        webCarousel = null;
        webGroup.visible = false;
        console.log('Set webCarousel to null and webGroup visibility to false');
      } else {
        console.log('No webCarousel to dispose, already null');
      }
    };

    // Animation loop
    const animate = () => {
      animationFrameId = requestAnimationFrame(animate);
      
      // Clear everything first
      renderer.clear();

      // Always update cosmos (persistent background)
      if (cosmos && cosmos.update) {
        cosmos.update();
      }

      // Update smiley face only if it exists (only on home page)
      if (smileyFace && smileyFace.update) {
        smileyFace.update();
        
        if (smileyFace.controls) {
          smileyFace.controls.update();
        }
      }
      
      // Update logo cubes (only on logo page)
      if (logoCubes) {
        // Update controls if they exist
        if (logoCubes.controls) {
          logoCubes.controls.update();
        }
        
        // Call the update function for animations including fade
        if (logoCubes.update) {
          logoCubes.update();
        }
      }
      
      // Update web carousel
      if (webCarousel && webCarousel.update) {
        webCarousel.update();
      }

      // IMPORTANT: Render cosmos with its camera first
      if (renderer && cosmos && cosmos.camera) {
        // Make cosmos camera only see cosmos layer
        cosmos.camera.layers.set(COSMOS_LAYER);
        renderer.render(scene, cosmos.camera);
      }
      
      // For smiley face, render only the smiley face objects
      if (isHomePage.value && renderer && smileyFace && smileyFace.camera) {
        // Clear depth but keep colors
        renderer.clearDepth();
        
        // Make sure smiley camera only sees smiley layer
        smileyFace.camera.layers.set(SMILEY_LAYER);
        
        // Render only smiley face objects
        renderer.render(scene, smileyFace.camera);
      }
      
      // For logo page, render only the logo cubes
      if (isLogoPage.value && renderer && logoCubes && logoCubes.camera) {
        // Clear depth but keep colors
        renderer.clearDepth();
        
        // Make sure logo camera only sees logo layer
        logoCubes.camera.layers.set(LOGO_LAYER);
        
        // Render only logo cubes
        renderer.render(scene, logoCubes.camera);
      }
      
      // For art page with art carousel
      if (isArtPage.value && renderer && artCarousel && artCarousel.camera) {
        renderer.clearDepth();
        artCarousel.camera.layers.set(ART_LAYER);
        renderer.render(scene, artCarousel.camera);
      }
      
      // For web page with web carousel
      if (isWebPage.value && renderer && webCarousel && webCarousel.camera) {
        renderer.clearDepth();
        webCarousel.camera.layers.set(WEB_LAYER);
        renderer.render(scene, webCarousel.camera);
      }
    };

    // Handle window resize
    const handleResize = () => {
      if (!renderer) return;

      // Update renderer
      renderer.setSize(window.innerWidth, window.innerHeight);

      // Update cosmos camera aspect ratio (always needed)
      if (cosmos && cosmos.camera) {
        cosmos.camera.aspect = window.innerWidth / window.innerHeight;
        cosmos.camera.updateProjectionMatrix();
      }
      
      // Update smiley face camera aspect ratio (only if on home page)
      if (smileyFace && smileyFace.camera) {
        smileyFace.camera.aspect = window.innerWidth / window.innerHeight;
        smileyFace.camera.updateProjectionMatrix();
      }
      
      // Update logo cubes camera aspect ratio (only if on logo page)
      if (logoCubes && logoCubes.camera) {
        logoCubes.camera.aspect = window.innerWidth / window.innerHeight;
        logoCubes.camera.updateProjectionMatrix();
      }

      // Add art carousel camera resize
      if (artCarousel && artCarousel.camera) {
        artCarousel.camera.aspect = window.innerWidth / window.innerHeight;
        artCarousel.camera.updateProjectionMatrix();
      }
      
      // Add web carousel camera resize
      if (webCarousel && webCarousel.camera) {
        webCarousel.camera.aspect = window.innerWidth / window.innerHeight;
        webCarousel.camera.updateProjectionMatrix();
      }
    };

    // Watch for route changes
    watch(() => route.path, (newPath, oldPath) => {
      console.log(`Route changed from ${oldPath} to ${newPath}`);
      const newIsHome = newPath === '/' || newPath === '/home';
      const newIsLogo = newPath === '/logo';
      const newIsInfo = newPath === '/info';
      const newIsArt = newPath === '/art';
      const newIsWeb = newPath === '/ux-ui';
      
      // Handle logo page changes
      if (newIsLogo && !isLogoPage.value) {
        console.log('Navigated TO logo page, updating state and loading cubes');
        isLogoPage.value = true;
        loadLogoCubes();
      }
      else if (!newIsLogo && isLogoPage.value) {
        console.log('Navigated AWAY FROM logo page, unloading cubes');
        isLogoPage.value = false;
        unloadLogoCubes();
      }
      
      // Handle home page changes
      if (newIsHome && !isHomePage.value) {
        isHomePage.value = true;
        loadSmileyFace();
      } 
      else if (!newIsHome && isHomePage.value) {
        isHomePage.value = false;
        unloadSmileyFace();
      }

      // Handle info page changes - ONLY update state, no model loading
      if (newIsInfo && !isInfoPage.value) {
        console.log('Navigated TO info page');
        isInfoPage.value = true;
      }
      else if (!newIsInfo && isInfoPage.value) {
        console.log('Navigated AWAY FROM info page');
        isInfoPage.value = false;
      }
      
      // Handle art page changes
      if (newIsArt && !isArtPage.value) {
        console.log('Navigated TO art page, loading art carousel');
        isArtPage.value = true;
        loadArtCarousel();
      }
      else if (!newIsArt && isArtPage.value) {
        console.log('Navigated AWAY FROM art page, unloading art carousel');
        isArtPage.value = false;
        unloadArtCarousel();
      }
      
      // Handle web page changes
      if (newIsWeb && !isWebPage.value) {
        console.log('Navigated TO web page, loading web carousel');
        isWebPage.value = true;
        loadWebCarousel();
      }
      else if (!newIsWeb && isWebPage.value) {
        console.log('Navigated AWAY FROM web page, unloading web carousel');
        isWebPage.value = false;
        unloadWebCarousel();
      }
    });

    // Lifecycle hooks
    onMounted(() => {
      // Add minimal delay to allow UI to become interactive first
      setTimeout(() => {
        initThree();
      }, 0);
      
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      // Stop animation loop
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }

      // Dispose component-specific resources
      unloadSmileyFace();
      unloadLogoCubes();
      unloadArtCarousel();
      unloadWebCarousel();

      // NOTE: We do NOT dispose cosmos background as it should remain persistent
      // disposeBackground() is deliberately not called

      // Clean up renderer
      if (renderer) {
        renderer.dispose();
        renderer.forceContextLoss();
        renderer = null;
      }

      // Remove event listener
      window.removeEventListener('resize', handleResize);
    });

    return {
      threeCanvas,
    };
  }
};
</script>

<style scoped>
.three-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.three-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
</style>