import { createRouter, createWebHistory } from 'vue-router'
import Cookies from 'js-cookie'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: 'Domfolio | Home',
    }
  },
  {
    path: '/ux-ui',
    name: 'Web',
    component: () => import('../views/UxUi.vue'),
    meta: {
      title: 'Domfolio | UX/UI',
    }
  },
  // Web routes as top-level
  {
    path: '/ux-ui/starz',
    name: 'WebStarz',
    component: () => import('../views/ux-ui/Starz.vue'),
    meta: {
      title: 'Domfolio | UX/UI | Starz',
    }
  },
  {
    path: '/ux-ui/neoera',
    name: 'WebNeoera',
    component: () => import('../views/ux-ui/Neoera.vue'),
    meta: {
      title: 'Domfolio | UX/UI | Neoera',
    }
  },
  {
    path: '/ux-ui/spectrum',
    name: 'WebSpectrum',
    component: () => import('../views/ux-ui/Spectrum.vue'),
    meta: {
      title: 'Domfolio | UX/UI | Spectrum',
    }
  },
  {
    path: '/ux-ui/hca',
    name: 'WebHca',
    component: () => import('../views/ux-ui/Hca.vue'),
    meta: {
      title: 'Domfolio | UX/UI | Hca',
    }
  },
  {
    path: '/ux-ui/yellow',
    name: 'WebYellow',
    component: () => import('../views/ux-ui/Yellow.vue'),
    meta: {
      title: 'Domfolio | UX/UI | Yellow',
    }
  },
  {
    path: '/logo',
    name: 'Logo',
    component: () => import('../views/Logo.vue'),
    meta: {
      title: 'Domfolio | Logo',
    },
    props: true
  },
  {
    path: '/art',
    name: 'Art',
    component: () => import('../views/Art.vue'),
    meta: {
      title: 'Domfolio | Art',
    }
  },
  // Art routes as top-level
  {
    path: '/art/noesis-landscape',
    name: 'ArtNoesisLandscape',
    component: () => import('../views/art/NoesisLandscape.vue'),
    meta: {
      title: 'Domfolio | Art | Noesis Landscape',
    }
  },
  {
    path: '/art/the-imgs',
    name: 'ArtTheImgs',
    component: () => import('../views/art/TheImgs.vue'),
    meta: {
      title: 'Domfolio | Art | The Imgs',
    }
  },
  {
    path: '/art/flimsy-currency',
    name: 'ArtFlimsyCurrency',
    component: () => import('../views/art/FlimsyCurrency.vue'),
    meta: {
      title: 'Domfolio | Art | Flimsy Currency',
    }
  },
  {
    path: '/art/tv-stills',
    name: 'ArtTvStills',
    component: () => import('../views/art/TvStills.vue'),
    meta: {
      title: 'Domfolio | Art | TV Stills',
    }
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import('../views/Info.vue'),
    meta: {
      title: 'Domfolio | Info',
    }
  },
  {
    path: '/splash',
    name: 'Splash',
    component: () => import('@/views/Splash.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Combined navigation guard with all checks
router.beforeEach(async (to, from, next) => {
  try {
    // If no matching route found, redirect to home
    if (to.matched.length === 0) {
      console.log('No route matched, redirecting to home');
      return next('/');
    }
    
    // Set page title
    document.title = to.meta.title || 'Domfolio';

    // Splash page logic - check for BOTH cookies for compatibility
    const hasVisited = Cookies.get('visited') || Cookies.get('lastEntry');

    if (to.path === '/splash') {
      // If already visited, redirect to home
      if (hasVisited) {
        return next('/');
      }
      return next();
    }

    // If haven't visited and trying to access home, redirect to splash
    if (to.path === '/' && !hasVisited && from.path !== '/splash') {
      return next('/splash');
    }

    // Normal navigation
    return next();
  } catch (error) {
    console.error('Navigation error:', error);
    return next('/');
  }
});

// Global error handler for route loading
router.onError((error) => {
  console.error('Router error:', error);
  router.push('/');
});

// Route resolution handler
router.beforeResolve((to, from, next) => {
  // No need to check for unmatched routes here as we already handle it in beforeEach
  Promise.all(
    to.matched.map(record => {
      const component = record.components.default;
      return typeof component === 'function' ? component() : Promise.resolve();
    })
  )
    .then(() => next())
    .catch(err => {
      console.error('Error loading route component:', err);
      next('/'); // Redirect to home on any error
    });
});

export default router
