export async function loadFonts() {
    if (!("fonts" in document)) {
        console.warn('Font loading API not supported');
        return false;
    }

    try {
        await Promise.all([
            document.fonts.load("1rem Gridlite"),
            document.fonts.load("300 1rem Coordinates"),
            document.fonts.load("300 1rem Coordinates italic")
        ]);

        document.documentElement.classList.add("fonts-loaded");
        return true;
    } catch (error) {
        console.error('Failed to load fonts:', error);
        return false;
    }
}

// Usage example in main.js:
